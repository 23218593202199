import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'faq',
    loadChildren: () => import('./faq/faq.module').then( m => m.FaqPageModule)
  },
  {
    path: 'ccm',
    loadChildren: () => import('./ccm/ccm.module').then( m => m.CcmPageModule)
  },
  {
    path: 'parcours',
    children: [
      {
        path: '',
        loadChildren: './parcours/parcours.module#ParcoursPageModule'
      },
      {
        path: ':trackId',
        loadChildren: './parcours/detail/detail.module#DetailPageModule'
      }
    ]
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'admin/parcours',
    loadChildren: () => import('./admin/tracks/tracks.module').then( m => m.TracksPageModule)
  },
  {
    path: 'admin/etapes',
    loadChildren: () => import('./admin/stages/stages.module').then( m => m.StagesPageModule)
  },
  {
    path: 'admin/stats',
    children: [
      {
        path: '',
        loadChildren: './admin/stats/stats.module#StatsPageModule'
      },
      {
        path: ':year',
        loadChildren: './admin/stats/stats.module#StatsPageModule'
      }
    ]
  },
  {
    path: 'confidentialite',
    loadChildren: () => import('./confidentialite/confidentialite.module').then( m => m.ConfidentialitePageModule)
  },
  {
    path: 'cgu',
    loadChildren: () => import('./cgu/cgu.module').then( m => m.CguPageModule)
  },
  {
    path: 'stats',
    loadChildren: () => import('./admin/stats/stats.module').then( m => m.StatsPageModule)
  },
  {
    path: 'blog/visiter-lausanne',
    loadChildren: () => import('./blog/visiter-lausanne/visiter-lausanne.module').then( m => m.VisiterLausannePageModule)
  },
  {
    path: 'blog/visiter-neuchatel',
    loadChildren: () => import('./blog/visiter-neuchatel/visiter-neuchatel.module').then( m => m.VisiterNeuchatelPageModule)
  },
  {
    path: 'blog/visiter-yverdon',
    loadChildren: () => import('./blog/visiter-yverdon/visiter-yverdon.module').then( m => m.VisiterYverdonPageModule)
  },
  {
    path: 'blog/visiter-geneve',
    loadChildren: () => import('./blog/visiter-geneve/visiter-geneve.module').then( m => m.VisiterGenevePageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
